<template>
  <div class="container">
    <van-pull-refresh v-model="refreshisLoading" @refresh="onRefresh">
      <van-loading
        class="loading"
        color="#9d1d22"
        text-color="#9d1d22"
        v-if="loading"
        size="24px"
        >加载中...</van-loading
      >
      <div v-if="loading == false">
        <!-- <van-sticky></van-sticky> -->
        <div></div>
        <van-nav-bar @click-left="toUserInfo()">
          <template #left>
            <van-image
              style="float: left"
              round
              width="41px"
              height="41px"
              :src="userInfo.headImgurl"
            />
            <h3 style="margin-left: 5px">{{ userInfo.joinTime }}</h3>

            <!-- <van-icon name="search" size="18" @click="onSearch()" /> -->
          </template>
        </van-nav-bar>
        <!-- <div v-if="workDay.length>0">{{workDay}}</div> -->

        <van-row>
          <van-col span="24" style="margin-top: 5px">
            <van-search
              v-model="keyword"
              @search="onSearch"
              shape="round"
              placeholder="请输入搜索关键词"
            />
          </van-col>
        </van-row>

        <!-- 轮播图 -->
        <van-swipe class="my-swipe" :autoplay="3000">
          <van-swipe-item v-for="(image, index) in swipeImages" :key="index">
            <img
              v-lazy="image.imageUrl"
              :data_link="image.link"
              @click="pimageClick(image.link)"
            />
          </van-swipe-item>
        </van-swipe>

        <van-notice-bar
          v-if="workDay!= undefined && workDay != ''"
          style="margin-top: 0px"
          left-icon="volume-o"
          :text="workDay"
        />


        <!--励志图文-->
        <div v-if="workDayImage != undefined && workDayImage != ''">
          <div
            style="text-align: center"
            class="workdayImageClick"
            @click="workDayImageShowClick"
          >
            <img
              style="max-height: 24px"
              :src="
                workDayImageShow ? '/image/up_icon.png': '/image/down_icon.png' 
              "
            />
          </div>

          <div v-show="workDayImageShow"  class="workdayImageItme" style="text-align: center">
            <img style="max-width: 90%" :src="workDayImage" />
          </div>
        </div>
        <!--导航-->
        <van-grid :gutter="0" :column-num="5" :route="true" :border="false">
          <van-grid-item
            v-for="(nav, index) in NavItmes"
            :key="index"
            v-bind:icon="nav.src"
            v-bind:text="nav.title"
            v-bind:to="nav.link"
            @click="navClick(nav)"
          />
        </van-grid>

        <!-- <div class="mydivider"></div> -->
        <van-notice-bar
          v-if="Notice != ''"
          style="margin-top: 10px"
          left-icon="volume-o"
          :text="Notice"
          @click="navNotice(NoticeItem)"
        />

        <div v-if="hottexts.length > 0 || hotimages.length > 0">
          <h2 style="padding: 2px 1px 5px 18px">热门推荐</h2>

          <!-- <van-row>
            <van-col
              span="12"
              v-for="(h, index) in hotimages"
              :key="index"
              @click="hotClick(h)"
            >
              <van-image
                lazy-load
                class="w100"
                fit="contain"
                style="max-height: 200px"
                :src="h.imageUrl"
              />

              <div style="height: 28px; padding: 8px 10px">
                <span
                  style="display: inline-block; height: 21px"
                  class="van-multi-ellipsis--l3"
                >
                  {{ h.title }}
                </span>
              </div>
              <div style="height: 25px; padding: 0px 10px">
                <div style="float: left; color: #a8a8a8">
                  {{ h.readCount }}人阅读
                </div>
                <div style="float: right">
                  <van-tag round color="#9d1d22" size="medium"
                    >点击前往</van-tag
                  >
                </div>
              </div>
            </van-col>
          </van-row> -->

          <van-swipe :autoplay="3500">
            <van-swipe-item v-for="(h2, index) in hotimagespuls" :key="index">
              <van-row>
                <van-col
                  span="12"
                  v-for="(h, index) in h2"
                  :key="index"
                  @click="hotClick(h)"
                >
                  <van-image
                    lazy-load
                    class="w100"
                    fit="contain"
                    style="max-height: 200px"
                    :src="h.imageUrl"
                  />

                  <div style="height: 28px; padding: 8px 10px">
                    <span
                      style="display: inline-block; height: 21px"
                      class="van-multi-ellipsis--l3"
                    >
                      {{ h.title }}
                    </span>
                  </div>
                  <div style="height: 25px; padding: 0px 10px">
                    <div style="float: left; color: #a8a8a8">
                      {{ h.readCount }}人阅读
                    </div>
                    <div style="float: right">
                      <van-tag round color="#9d1d22" size="medium"
                        >点击前往</van-tag
                      >
                    </div>
                  </div>
                </van-col>
              </van-row>
            </van-swipe-item>
          </van-swipe>

          <div class="mydivider" v-if="hotimages.length > 0"></div>

          <van-list>
            <van-cell
              v-for="(h, index) in hottexts"
              :key="index"
              @click="hotClick(h)"
            >
              <div class="tops" style="float: left">
                <div>Top{{ index + 1 }}</div>
              </div>
              <div style="float: left; margin-left: 4px">
                {{ h.title }}
              </div>
              <div style="clear: left; color: #a8a8a8">
                {{ h.readCount }}人阅读
              </div>
            </van-cell>
          </van-list>
        </div>

        <div v-if="newAtricle102.length > 0">
          <van-row>
            <van-col span="18">
              <h2 style="padding: 2px 1px 5px 18px">进阶S7攻略</h2>
            </van-col>
            <van-col span="6" @click="pimageClick('/s7strategy/list?type=b')">
              <div style="float: right; padding: 8px 20px 0px 0px">
                更多<van-icon name="arrow" /></div
            ></van-col>
          </van-row>
          <van-list>
            <van-cell
              v-for="(h, index) in newAtricle102"
              :key="index"
              @click="hotClick(h)"
            >
              <van-col span="6"
                ><van-image
                  lazy-load
                  class="w100"
                  fit="contain"
                  style="max-height: 200px"
                  :src="h.imageUrl"
                />
              </van-col>
              <van-col span="18" align="bottom">
                <div
                  style="display: table-cell; vertical-align: middle"
                  class="zntitle van-multi-ellipsis--l3"
                >
                  <h3 style="display: inline-block">{{ h.title }}</h3>
                </div>
                <div style="float: right">{{ h.creationTime }}</div>
                <div style="float: right; margin-right: 13px">
                  浏览{{ h.readCount }}人次
                </div>
              </van-col>
            </van-cell>
          </van-list>
        </div>

        <div v-if="newAtricle106.length > 0">
          <van-row>
            <van-col span="18">
              <h2 style="padding: 2px 1px 5px 18px">市场宣传</h2>
            </van-col>
            <van-col span="6" @click="pimageClick('/article?type=106')">
              <div style="float: right; padding: 8px 20px 0px 0px">
                更多<van-icon name="arrow" /></div
            ></van-col>
          </van-row>

          <van-row>
            <van-col
              span="12"
              v-for="(h, index) in newAtricle106"
              :key="index"
              @click="hotClick(h)"
            >
              <van-image
                lazy-load
                class="w100"
                fit="contain"
                style="max-height: 200px"
                :src="h.imageUrl"
              />

              <div style="height: 28px; padding: 8px 10px">
                <span
                  style="display: inline-block; height: 21px"
                  class="van-multi-ellipsis--l3"
                >
                  {{ h.title }}
                </span>
              </div>
              <div style="height: 25px; padding: 0px 10px">
                <div style="float: left; color: #a8a8a8">
                  {{ h.readCount }}人阅读
                </div>
                <div style="float: right">
                  <van-tag round color="#9d1d22" size="medium"
                    >点击前往</van-tag
                  >
                </div>
              </div>
              <div class="mydivider"></div>
            </van-col>
          </van-row>
        </div>

        <!-- <div class="mydivider"></div> -->
        <div v-if="newAtricle100.length > 0">
          <van-row>
            <van-col span="18">
              <h2 style="padding: 2px 1px 5px 18px">中酿资讯</h2>
            </van-col>
            <van-col span="6" @click="pimageClick('/article?type=100')">
              <div style="float: right; padding: 8px 20px 0px 0px">
                更多<van-icon name="arrow" /></div
            ></van-col>
          </van-row>
          <van-list>
            <van-cell
              v-for="(h, index) in newAtricle100"
              :key="index"
              @click="hotClick(h)"
            >
              <van-col span="6"
                ><van-image
                  lazy-load
                  class="w100"
                  fit="contain"
                  style="max-height: 200px"
                  :src="h.imageUrl"
                />
              </van-col>
              <van-col span="18" align="bottom">
                <div
                  style="display: table-cell; vertical-align: middle"
                  class="zntitle van-multi-ellipsis--l3"
                >
                  <h3 style="display: inline-block">{{ h.title }}</h3>
                </div>
                <div style="float: right">{{ h.creationTime }}</div>
                <div style="float: right; margin-right: 13px">
                  浏览{{ h.readCount }}人次
                </div>
              </van-col>
            </van-cell>
          </van-list>
        </div>

        <div v-if="newAtricle101.length > 0">
          <van-row>
            <van-col span="18">
              <h2 style="padding: 2px 1px 5px 18px">中酿公告</h2>
            </van-col>
            <van-col span="6" @click="pimageClick('/article?type=101')">
              <div style="float: right; padding: 8px 20px 0px 0px">
                更多<van-icon name="arrow" /></div
            ></van-col>
          </van-row>

          <van-list>
            <van-cell
              v-for="(h, index) in newAtricle101"
              :key="index"
              @click="hotClick(h)"
            >
              <van-col span="6"
                ><van-image
                  lazy-load
                  class="w100"
                  fit="contain"
                  style="max-height: 200px"
                  :src="h.imageUrl"
                />
              </van-col>
              <van-col span="18" align="bottom">
                <div
                  style="display: table-cell; vertical-align: middle"
                  class="zntitle van-multi-ellipsis--l3"
                >
                  <h3 style="display: inline-block">{{ h.title }}</h3>
                </div>
                <div style="float: right">{{ h.creationTime }}</div>
                <div style="float: right; margin-right: 13px">
                  浏览{{ h.readCount }}人次
                </div>
              </van-col>
            </van-cell>
          </van-list>
        </div>

        <div v-if="newProduct.length > 0">
          <van-row>
            <van-col span="18">
              <h2 style="padding: 2px 1px 5px 18px">中酿产品</h2>
            </van-col>
            <van-col span="6" @click="pimageClick('/product/list')">
              <div style="float: right; padding: 8px 20px 0px 0px">
                更多<van-icon name="arrow" /></div
            ></van-col>
          </van-row>

          <van-row>
            <van-col
              span="12"
              v-for="(h, index) in newProduct"
              :key="index"
              @click="hotClick(h)"
            >
              <van-image
                lazy-load
                class="w100"
                fit="contain"
                style="max-height: 200px"
                :src="h.imageUrl"
              />

              <div style="height: 28px; padding: 8px 10px">
                <span
                  style="display: inline-block; height: 21px"
                  class="van-multi-ellipsis--l3"
                >
                  {{ h.title }}
                </span>
              </div>
              <div style="height: 25px; padding: 0px 10px">
                <div style="float: left; color: #a8a8a8">
                  {{ h.readCount }}人阅读
                </div>
                <div style="float: right">
                  <van-tag round color="#9d1d22" size="medium"
                    >点击前往</van-tag
                  >
                </div>
              </div>
              <div class="mydivider"></div>
            </van-col>
          </van-row>
        </div>
        <div v-if="newCourse.length > 0">
          <van-row>
            <van-col span="18">
              <h2 style="padding: 2px 1px 5px 18px">中酿学院 线上课程</h2>
            </van-col>
            <van-col span="6" @click="pimageClick('/course?type=a')">
              <div style="float: right; padding: 8px 20px 0px 0px">
                更多<van-icon name="arrow" /></div
            ></van-col>
          </van-row>

          <van-list>
            <van-cell
              v-for="(h, index) in newCourse"
              :key="index"
              @click="hotClick(h)"
            >
              <van-col span="6"
                ><van-image
                  lazy-load
                  class="w100"
                  fit="contain"
                  style="max-height: 200px"
                  :src="h.imageUrl"
                />
              </van-col>
              <van-col span="18" align="bottom">
                <div
                  style="display: table-cell; vertical-align: middle"
                  class="zntitle van-multi-ellipsis--l3"
                >
                  <h3 style="display: inline-block">{{ h.title }}</h3>
                </div>
                <div style="float: right">{{ h.creationTime }}</div>
                <div style="float: right; margin-right: 13px">
                  浏览{{ h.readCount }}人次
                </div>
              </van-col>
            </van-cell>
          </van-list>
        </div>
        <div v-if="newCourseOffline.length > 0">
          <van-row>
            <van-col span="18">
              <h2 style="padding: 2px 1px 5px 18px">中酿学院 线下课程</h2>
            </van-col>
            <van-col span="6" @click="pimageClick('/course?type=b')">
              <div style="float: right; padding: 8px 20px 0px 0px">
                更多<van-icon name="arrow" /></div
            ></van-col>
          </van-row>

          <van-list>
            <van-cell
              v-for="(h, index) in newCourseOffline"
              :key="index"
              @click="hotClick(h)"
            >
              <van-col span="6"
                ><van-image
                  lazy-load
                  class="w100"
                  fit="contain"
                  style="max-height: 200px"
                  :src="h.imageUrl"
                />
              </van-col>
              <van-col span="18" align="bottom">
                <div
                  style="display: table-cell; vertical-align: middle"
                  class="zntitle van-multi-ellipsis--l3"
                >
                  <h3 style="display: inline-block">{{ h.title }}</h3>
                </div>
                <div style="float: right">{{ h.creationTime }}</div>
                <div style="float: right; margin-right: 13px">
                  浏览{{ h.readCount }}人次
                </div>
              </van-col>
            </van-cell>
          </van-list>
        </div>

        <div v-if="newBBs.length > 0">
          <van-row>
            <van-col span="18">
              <h2 style="padding: 2px 1px 5px 18px">中酿心声</h2>
            </van-col>
            <van-col span="6" @click="pimageClick('/bbs')">
              <div style="float: right; padding: 8px 20px 0px 0px">
                更多<van-icon name="arrow" /></div
            ></van-col>
          </van-row>

          <van-list>
            <van-cell
              v-for="(h, index) in newBBs"
              :key="index"
              @click="hotClick(h)"
            >
              <van-col span="3">
                <van-image
                  style="float: left"
                  round
                  width="41px"
                  height="41px"
                  :src="h.headImgurl"
                />
              </van-col>

              <van-col span="11">
                <div
                  style="
                    float: left;
                    color: #069;
                    font-size: 16px;
                    font-weight: bold;
                    width: 100%;
                  "
                >
                  {{ h.trueName }}
                </div>

                <div style="float: left; color: #696969">
                  {{ h.creationTime | relTime }}
                </div>
                <div style="float: left; color: #696969; margin-left: 13px">
                  浏览{{ h.readCount }}人次
                </div>
              </van-col>
              <van-col span="10"> </van-col>

              <van-col span="2"></van-col>

              <van-col span="22">
                <div style="float: left; margin-left: 4px">
                  {{ h.title }}
                </div>
              </van-col>
              <!-- <div class="tops" style="float: left">
              <div>Top{{ index + 1 }}</div>
            </div> -->
            </van-cell>
          </van-list>
        </div>

        <!-- <div v-if="newCloudDisk.length > 0">
        <h2 style="padding: 2px 1px 5px 18px">资料</h2>
        <van-list>
          <van-cell
            v-for="(h, index) in newCloudDisk"
            :key="index"
            @click="hotClick(h)"
          >
            <div class="tops" style="float: left">
              <div>Top{{ index + 1 }}</div>
            </div>
            <div style="float: left; margin-left: 4px">
              {{ h.title }}
            </div>
            <div style="clear: left; color: #a8a8a8">
              {{ h.readCount }}人阅读
            </div>
          </van-cell>
        </van-list>
      </div> -->
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Toast } from "vant";
//import store from '@/store'
import {
  getSetingList,
  updateReadCount,
  GetWorkDay,
  GetShareEndeavorImage,
} from "@/api/seting";
import { GetUserWelcome } from "@/api/user";
import { GetSystemScore } from "@/api/score";
import store from "@/store";

export default {
  name: "test",
  data() {
    return {
      refreshisLoading: false,
      userInfo: {},
      loading: true,
      Notice: "",
      NoticeItem: {},
      keyword: "",
      swipeImages: [],
      NavItmes: [],
      hotimagespuls: [],
      hotimages: [],
      hottexts: [],
      newAtricle100: [],
      newAtricle101: [],
      newAtricle102: [],
      newAtricle106: [],
      newProduct: [],
      newCourse: [],
      newCourseOffline: [],
      newBBs: [],
      newCloudDisk: [],
      workDay: "",
      activeNames: ["1"],
      workDayImage: "",
      workDayImageShow: true,
    };
  },
  created() {
    console.log("created");

    // if(store.state.user.trueName!=undefined){
    //     //this.trueName=store.state.user.trueName

    // }
    if (this.NavItmes.length == 0) {
      this.initData();
    }
  },
  // watch: {
  //   $route(to, from) {
  //     //监听路由是否变化
  //     if (to.query != from.query) {
  //       this.initData();
  //     }
  //   },
  // },
  activated() {
    console.log("activated");
    if (this.NavItmes.length == 0) {
      this.initData();
    }
  },
  beforeCreate() {},
  methods: {
    onRefresh() {
      (this.loading = true), this.initData();
      this.refreshisLoading = false;
      Toast("刷新成功");
    },
    async initData() {
      this.swipeImages = [];
      this.NavItmes = [];
      this.hotimages = [];
      this.hotimagespuls = [];
      this.hottexts = [];
      this.newAtricle100 = []; //10100
      this.newAtricle101 = []; // 10101
      this.newAtricle102 = []; // 10102
      this.newAtricle106 = [];
      this.newProduct = []; //2
      this.newCourse = []; //3
      this.newCourseOffline = []; //3
      this.newBBs = []; //4
      this.newCloudDisk = []; //5

      this.activeNames = ["1"];

      var welcome = await GetUserWelcome();
      console.log("welcome");
      if (welcome.data.success) {
        this.userInfo = welcome.data.data;
        if (
          welcome.data.scoreMessage !== undefined &&
          welcome.data.scoreMessage != ""
        ) {
          Toast({
            message: welcome.data.scoreMessage,
            icon: "/image/signin_point_add.png",
          });
        }
      }

      //功能模块
      let result101 = await getSetingList({
        fromType: 1,
        current: 1,
        pageSize: 50,
        FilterData: { setTypes: [100, 101, 102, 103, 104] },
      });
      if (result101.data.data.length > 0) {
        this.NavItmes = [];
        result101.data.data.forEach((element) => {
          //轮播图
          if (element.setType == 100) {
            this.swipeImages.push({
              imageUrl: element.imageUrl,
              link: element.urlPath,
            });
          }

          //功能模块
          if (element.setType == 101) {
            this.NavItmes.push({
              src: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              creationTime: element.creationTime,
            });
          }
          //TODO: 滚动文注释
          // //滚动文字
          if (element.setType == 102) {
            this.NoticeItem = {
              link: element.urlPath,
              title: element.value,
            };
            this.Notice = element.value;
          }
          //热门推荐带图
          if (element.setType == 103) {
            this.hotimages.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }
          //热门推荐不带图
          if (element.setType == 104) {
            this.hottexts.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }

          if (element.setType == 106) {
            this.hottexts.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }

          if (element.setType == 10000) {
            this.newAtricle100.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }

          if (element.setType == 10100) {
            this.newAtricle101.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }
          if (element.setType == 10200) {
            this.newAtricle102.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }

          if (element.setType == 10600) {
            this.newAtricle106.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }

          if (element.setType == 3) {
            this.newProduct.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }
          if (element.setType == 4) {
            this.newCourse.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }
          if (element.setType == 41) {
            this.newCourseOffline.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }

          //中酿心声
          if (element.setType == 5) {
            this.newBBs.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
              headImgurl: element.headImgurl,
              trueName: element.trueName,
            });
          }

          if (element.setType == 6) {
            this.newCloudDisk.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
              headImgurl: element.headImgurl,
              trueName: element.trueName,
            });
          }

          //
        });
      }
      if (this.hotimages.length > 0) {
        this.hotimagespuls = [];
        let h2 = [];
        for (let index = 0; index < this.hotimages.length; index++) {
          h2.push(this.hotimages[index]);
          if (h2.length == 2 || index == this.hotimages.length - 1) {
            this.hotimagespuls.push(h2);
            h2 = [];
          }
        }
      }

      var systemScore = await GetSystemScore();

      var workDayResult = await GetWorkDay();
      if (workDayResult.data.success) {
        this.workDay = workDayResult.data.data;
      } else {
        this.workDay = "";
      }
      var workDayImageResult = await GetShareEndeavorImage();
      if (workDayImageResult.data.success) {
        this.workDayImage = workDayImageResult.data.data;
      } else {
        this.workDayImage = "";
      }

      this.loading = false;

      if (
        welcome.data.scoreMessage !== undefined &&
        welcome.data.scoreMessage != ""
      ) {
        Toast({
          message: welcome.data.scoreMessage,
          icon: "/image/signin_point_add.png",
        });
      }

      //有系统积分
      if (
        systemScore.data.scoreMessage !== undefined &&
        systemScore.data.scoreMessage != ""
      ) {
        if (
          welcome.data.scoreMessage !== undefined &&
          welcome.data.scoreMessage != ""
        ) {
          //两秒后   var welcome = await GetUserWelcome();

          const timer = setInterval(() => {
            clearInterval(timer);
            Toast({
              message: systemScore.data.scoreMessage,
              icon: "/image/signin_point_add.png",
            });
          }, 2000);
        } else {
          Toast({
            message: systemScore.data.scoreMessage,
            icon: "/image/signin_point_add.png",
          });
        }
      }

      setTimeout(() => {
        this.activeNames = [];
        //Toast("折叠");
      }, 3000);
    },
    pimageClick(link) {
      if (link != "") {
        if (link.indexOf("http") >= 0) {
          window.location.href = link;
        } else {
          this.$router.push(link);
        }
      }
    },

    workDayImageShowClick() {
      this.workDayImageShow = !this.workDayImageShow;
    },

    hotClick(h) {
      if (h.link != "") {
        this.$router.push(h.link);
        //阅读量加1
        updateReadCount(h.id);
      }
    },
    navClick(nav) {
      if (nav.link == undefined || nav.link == "") {
        Toast(nav.title + " 功能开发中，敬请期待");
      }
      //Grafana
      if (nav.link.indexOf("http://182.48.111.206:8100") >= 0) {
        window.location.href =
          "http://182.48.111.206:8100/public/pages/login.html?user=" +
          store.state.user.code +
          "&pwd=" +
          store.state.user.id.toUpperCase();
      } else if (
        nav.link.indexOf("http:") >= 0 ||
        nav.link.indexOf("https:") >= 0
      ) {
        window.location.href = nav.link + "?token=" + store.state.user.token;
      }
    },

    navNotice(nav) {
      if (nav.link == undefined || nav.link == "") {
        console.log("没有跳转信息");
      } else {
        console.log(nav.link.indexOf("http"));
        if (nav.link.indexOf("http") >= 0) {
          window.location.href = nav.link;
        } else {
          this.$router.push({ path: nav.link });
        }
      }
    },

    onSearch() {
      //if (!this.keyword) return
      //Toast("全局搜索功能开发中，敬请期待");
      //onSearch

      this.$router.push({ path: "/search/list", query: { q: this.keyword } });
    },

    toUserInfo() {
      this.$router.push({
        path: "/user",
        query: {},
      });
    },
  },
};
</script>

<style lang="less" scoped>



@nav-bar-height:100px;



.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;

  /deep/.w100 {
    margin:2px 8px 2px 8px;
  // /deep/ img {
  //   width: 100%;
  //   height: 80px;
  //   max-height: 90px;
  // }
  img {
    width: 100%;
    //height: 80px;
    max-height: 150px;
  }
 
}
 /deep/.van-grid-item__content {
    padding: 16px 3px;
  }
}
.mydivider {
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin: 10px 0;
    color: #969799;
    font-size: 14px;
    line-height: 24px;
    border-color: #ebedf0;
    border-style: solid;
    border-width: 0;
    
  }
  .mydivider::before {
    content: '';
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    box-sizing: border-box;
    height: 1px;
    border-color: inherit;
    border-style: inherit;
    border-width: 1px 0 0;
}


.tops{
  font-size: 10px;
  transform: skewX(-30deg);
  background: linear-gradient(to right, #8f2e32, #9d1d22); 
  color: #fff;
  text-align: center;
  padding: 0px 5px;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 5px;
  box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.5);
  /deep/ div{
 transform: skewX(30deg);
}
}



 .midText {
    position: absolute;
    left: 50%;
    background-color: #ffffff;
    padding: 0 15px;
    transform: translateX(-50%) translateY(-50%);
  }
  .workdayImageClick:active{
    background-color: #fffff0;
  }
  .workdayImageItme{
    
  }


  

.loading{
  text-align: center;
}
.van-search {
padding: 0px 18px;
}
.van-grid{

/deep/ .van-icon__image{
  width: 1em;
  height: 1em;
}
}


.my-swipe .van-swipe-item {
    width: 100%;
    height: 150px;
    color: #fff;
    font-size: 20px;
    text-align: center
    //# background-color: #39a9ed;
  }

  .my-swipe img{
    display: inline-block;
    width: 100%;
    height: 100%;
}



.van-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/ .van-tabs__wrap {
    height: 36px;
    padding-right: 36px;
    .van-tab {
      line-height: 36px;
    }
    .van-tabs__line {
      background-color: #3296fa;
      height: 2px;
    }
  }
  /deep/ .van-tabs__content {
    flex: 1;
    overflow: hidden;
  }
  /deep/ .van-tab__pane {
    height: 100%;
    .scroll-wrapper {
      height: 100%;
      overflow-y: auto;
    }
  }
}
.bar_btn {
  width: 36px;
  height: 35px;
  position: absolute;
  top: 0;
  right: 0;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
    box-shadow: 0 0 10px #999;
    transform: scale(1, 0.6);
  }
  .van-icon-wap-nav {
    width: 100%;
    height: 100%;
    background: #fff;
    text-align: center;
    line-height: 35px;
    position: relative;
    z-index: 1000;
    &::before {
      font-size: 20px;
    }
  }
}
.van-action-sheet {
  max-height: 100%;
  height: 100%;
  .van-action-sheet__header {
    background: #3296fa;
    color: #fff;
    .van-icon-close {
      color: #fff;
    }
  }
}
.van-notice-bar {
  background:#fff;
  color:black;
  /deep/ i{
    color: #9d1d22;
  }

}
</style>
